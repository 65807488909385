import { template as template_ef08e98949ae4d42a0ad1f02501c3221 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_ef08e98949ae4d42a0ad1f02501c3221(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
