import { template as template_af8c137adb0145ff8d071b657c1a6cae } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_af8c137adb0145ff8d071b657c1a6cae(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
